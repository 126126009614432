import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import {
	Combobox,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from '@reach/combobox'
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from '@reach/disclosure'
import {
	Slider,
	SliderInput,
	SliderTrack,
	SliderTrackHighlight,
	SliderHandle,
	SliderMarker,
} from '@reach/slider'
import '@reach/slider/styles.css'
import TimePicker from 'react-time-picker'
import DatePicker from 'react-date-picker'
import axios from 'axios'
import { print } from 'graphql'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { Lottie } from '@crello/react-lottie'
import * as animationData from '../animations/loading.json'
import * as animationToggle from '../animations/toggle.json'
import * as animationCheckbox from '../animations/checkbox.json'
import qs from 'qs'
import moment from 'moment'
import tw, { css, styled } from 'twin.macro'

import Layout from '../components/layout'
import Meta from '../components/meta'
import PopUp from '../components/modal/forms'
import {
	StyledForm,
	ButtonRow,
	Button,
	Input,
	Select,
	Textarea,
	SelectIcon,
	SelectWrap,
	StyledCheckBox,
	FieldSet,
	FieldRow,
	FieldWrap,
	Legend,
	Label,
	StyledError,
	StyledHelp,
	Required,
	StyledComboboxInput,
} from '../components/form/styles'
import Checkbox from '../components/form/checkbox'
import Toggle from '../components/form/toggle'
import BannerImage from '../images/m-600-sitting-on-pool-edge.jpg'
import { CLOSEST_DEALER } from '../app/schema/dealer'
import { Calendar, X, Clock, XCircle } from 'react-feather'
import { graphql } from 'gatsby'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../components/utils/filter-locale'
import { DialogOverlay, DialogContent } from '@reach/dialog'

const BookADemoBtn = styled(Button)`
	${tw`bg-gray-700 hover:bg-gray-800 text-white`}
`

const Banner = styled.div`
	@media (min-width: 1024px) {
		background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.5),
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0)
			),
			url(${({ bg }) => `${bg[0]}`});
		${tw`w-full bg-no-repeat bg-cover bg-center relative pt-10`}
		height: 600px;
	}
	@media (min-width: 1280px) {
		background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0.5),
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0)
			),
			url(${({ bg }) => `${bg[0]}`});
		${tw`w-full bg-no-repeat bg-cover bg-center relative pt-10`}
		height: 900px;
	}
	@media (max-width: 1023px) {
		background: url(${({ bg }) => `${bg[1]}`});
		background-color: #f2f3f5;
		${tw`w-full bg-no-repeat bg-cover bg-bottom relative pt-10`}
		height: 600px;
	}
	@media (max-width: 767px) {
		background: url(${({ bg }) => `${bg[2]}`});
		${tw`w-full bg-no-repeat bg-cover bg-bottom relative pt-6`}
		height: 500px;
	}
`
const BannerContents = styled.div`
	${tw`grid-cols-12 gap-4 grid px-4 xl:px-8`}
`
const BannerLeft = styled.div`
	${tw`text-center xl:text-left pl-0 xl:pl-4 xl:pl-0 col-span-12 xl:col-span-6 xxxl:col-span-5 xxl:ml-8 xxxl:ml-16`}

	@media (max-width: 767px) {
		> h1 {
			${tw`text-4xl`}
		}
	}
`
const ReasonsContainer = styled.div`
	${tw`hidden xl:block mt-8 xl:w-10/12 w-8/12`}
`
const ReasonsTitle = styled.h2`
	${tw`font-display text-2xl pb-3 text-white font-bold border-b-4 border-white border-solid`}
`
const ListContainer = styled.div`
	${tw`grid-cols-12 gap-y-4 gap-x-8 grid pt-6`}
`
const ListDiv = styled.div`
	${tw`col-span-6 border-b border-white pb-4`}

	> p:nth-of-type(2) {
		${tw`text-xs mt-1`}
	}
`
const ListParagraph = tw.p`text-white font-display leading-6`
const BannerRight = styled.div`
	/* ${tw`w-8/12 md:w-4/12 mx-auto lg:mx-0 lg:w-auto col-span-12 xl:col-span-4 xxl:col-span-4 xl:col-start-9 xxl:col-start-9 lg:-ml-12 xl:-ml-12 xxl:ml-0 lg:-mr-16 xl:mr-0 flex flex-col`} */
	${tw`w-8/12 md:w-4/12 mx-auto lg:w-auto col-span-12 xl:col-span-5 xxl:col-span-5 xl:col-start-8 xxl:col-start-8 xxl:mr-8 xxxl:mr-16 flex flex-col`}

	> img {
		${tw`mt-20`}
	}
`
const BookNow = tw.a`bg-yellow-500 uppercase px-6 py-2 font-display mb-2 md:mb-4 text-center font-bold rounded-lg hover:bg-yellow-400 max-w-xs w-full mx-auto block xl:hidden`
const Phone = tw.a`bg-blue-500 px-6 py-2 font-display text-center font-bold rounded-lg hover:bg-blue-400 max-w-xs w-full mx-auto block xl:hidden`

const Testimonial = styled.div`
	${tw`hidden xl:block text-center pt-8 pb-4 px-6 mt-6 xxl:mt-10 xxxl:mt-12 leading-5 w-11/12`}
	background: linear-gradient(to right, #446f82 0%, #293e51 100%);
`
const StarsContainer = styled.div`
	${tw`flex flex-row justify-center py-4`}
`
const MobileReasonsContainer = styled.div`
	${tw`mt-6`}
`
const MobileContainer = styled.div`
	${tw`text-center px-4 py-10 w-full block xl:hidden`}
	background-color: #f2f3f5;
`
const MobileReasonsTitle = styled.h2`
	${tw`text-xl font-display font-bold w-10/12 mx-auto`}
`
const MobileListDiv = styled.div`
	${tw`relative`}
	> h3 {
		${tw`mt-4`}
	}
	> p:nth-of-type(2) {
		${tw`text-xs mt-1`}
	}

	:not(:last-of-type):before {
		content: '';
		position: absolute;
		width: 100px;
		bottom: -21%;
		left: calc(50% - 50px);
		border-bottom: 1px solid #353e47;
	}
`
const MobileListParagraph = styled.p`
	${tw`font-display font-medium text-sm mt-2`}
`
const StyledSliderMarker = styled(SliderMarker)`
	${tw`rounded-full w-3 h-3 border-2 border-white bg-gray-500`}
	&[data-reach-slider-marker-highlight] {
		${tw`bg-black`}
	}
`
const DialogPanel = styled(DialogContent)`
	${tw`text-left py-4 px-2 bg-white rounded relative`}
	h3 {
		${tw`font-display text-lg mb-3`}
	}
	p {
		${tw`font-sans text-sm mb-2`}
	}
	ul {
		${tw`font-sans text-sm mb-2 list-disc list-outside pl-4`}
	}

	@media (max-width: 1279px) {
		&[data-reach-dialog-content] {
			width: 90vw;
		}
	}
`
const CloseButton = styled(XCircle)`
	${tw`cursor-pointer ml-auto absolute`}
	right: 1rem;
	top: 1rem;
`
const BookADemoCheckbox = styled(CustomCheckboxInput)`
	& + .lottie-container {
		svg {
			${tw`stroke-current`}
			path {
				${({ invalid }) =>
					invalid ? tw`stroke-current text-red-500` : tw`stroke-current`};
			}
		}
	}
`
const AcceptTerms = tw.div`flex w-full md:w-1/2 my-4 items-center text-base`
const ToggleBox = styled.div`
	${tw`flex items-center justify-center w-full px-4`}
	height: 40px;
	transition: 0.3s 0.3s ease-in-out;
	.state {
		${tw`bg-transparent hover:bg-gray-500 text-gray-700 text-sm rounded py-1 px-2`}
		transition: 0.3s 0.3s ease-in-out;
	}
	.active {
		${tw`bg-gray-700 hover:bg-gray-800 text-white`}
		transition: 0.3s 0.3s ease-in-out;
	}
	svg path {
		fill-opacity: 1;
		${tw`text-white fill-current`}
	}
`
const CheckboxContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`
const Heading = styled.h1`
	${tw`font-display text-4xl font-bold text-gray-800 mt-8 mb-2 text-center`}
`

const Reminder = styled.p`
	${tw`font-display py-2 text-black mb-3 text-sm`}
	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`

/**
 * Determin how many working days from a date,
 * currently set to exlude Sunday as the weekend
 * @see today
 * @param {number} days number of working days
 * @returns {Date} date from working (x) days
 */
moment.fn.addWorkdays = function(days) {
	const increment = days / Math.abs(days)
	const date = this.clone().add(
		Math.floor(Math.abs(days) / 6) * 7 * increment,
		'days'
	)
	let remaining = days % 6
	while (remaining !== 0) {
		date.add(increment, 'days')
		if (date.isoWeekday() !== 7) remaining -= increment
	}
	return date
}
/** Today, used to determin working days */
const today = moment()

const validationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('field is required'),
	lastName: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('field is required'),
	email: Yup.string()
		.email('Invalid email')
		.required('field is required'),
	phone: Yup.string()
		.min(6, 'Too Short!')
		.max(11, 'Too Long!')
		.required('field is required'),
	fullAddress: Yup.string()
		.min(2, 'Too Short!')
		.max(180, 'Too Long!')
		.required('address is required'),
	date: Yup.date()
		.min(
			moment()
				.add(1, 'days')
				.format('YYYY-MM-DD'),
			'earliest is tomorrow'
		)
		.max(
			moment()
				.add(90, 'days')
				.format('YYYY-MM-DD'),
			'latest is 90 days'
		)
		.required('date is required'),
	time: Yup.string()
		.required('time is required')
		.matches(/[0-9]{2}:[0-9]{2}:[0-9]{2}/, 'Invalid format: 00:00:00')
		.test('is-between', 'between 08:00 and 17:00', time => {
			return moment(time, 'HH:mm:ss').isBetween(
				moment('07:59:00', 'HH:mm:ss'),
				moment('17:01:00', 'HH:mm:ss')
			)
		}),
	terms: Yup.boolean()
		.required('please accept terms')
		.test('is-accepted', 'please accept terms', terms => {
			return terms === true
		}),
	readandagree: Yup.boolean()
		.required('The Privacy Policy and Terms & Conditions must be accepted.')
		.oneOf(
			[true],
			'The Privacy Policy and Terms & Conditions must be accepted.'
		),
})
const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	date: moment()
		.add(1, 'days')
		.format('YYYY-MM-DD'),
	time: '08:00:00',
	fullAddress: '',
	address: {},
	branchEmail: 'customercareau@maytronics.com',
	material: '',
	isClean: false,
	product: '',
	size: 1,
	terms: false,
	createdAt: new Date(),
	dueAt: today.addWorkdays(3),
	readandagree: false,
	updates: false,
}
export const getData = async args => {
	const TOKEN = process.env.GATSBY_MAPS_TOKEN
	const {
		address: { fullAddress, postalCode },
		address,
	} = args
	const SEARCH_TEXT = encodeURI(
		address ? address : fullAddress ? fullAddress : postalCode
	)
	const URI = `https://maps.googleapis.com/maps/api/geocode/json?address=${SEARCH_TEXT}&key=${TOKEN}`
	const response = await axios.get(URI)
	if (response.status === 404)
		throw new Error('Google Maps found no matches for that address / postcode.')
	return response.data
}

export const getAddress = async args => {
	const data = await getData(args)
	const getComponent = (array, type) => {
		return array.filter(x => x.types.includes(type)).map(x => x.long_name)[0]
	}
	const results = data && data.results && data.results[0]
	const array = results?.address_components
	const sub = getComponent(array, 'subpremise')
	const number = getComponent(array, 'street_number')
	const road = getComponent(array, 'route')
	const city = getComponent(array, 'locality')
	const state = getComponent(array, 'administrative_area_level_1')
	const postalCode = getComponent(array, 'postal_code')
	const country = getComponent(array, 'country')
	const line1 = sub ? `${sub}/${number} ${road}` : `${number} ${road}`
	const fullAddress = results?.formatted_address
	const coordinates = [
		results?.geometry.location.lng,
		results?.geometry.location.lat,
	]
	return { line1, city, state, postalCode, country, fullAddress, coordinates }
}
export default function BookADemo({ data: { desktop, mobile } }) {
	const locale = 'en-AU'
	const defaultLocale = 'en-AU'
	const [
		{
			node: { title, items },
		},
	] = filterLocale(desktop.edges, locale)

	const [
		{
			node: { items: itemsMobile },
		},
	] = filterLocale(mobile.edges, locale)

	const [showDialog, setShowDialog] = useState(false)
	const open = () => setShowDialog(true)
	const close = () => setShowDialog(false)
	const [sent, setSent] = useState(false)
	const poolSize = size => {
		switch (size) {
			case 1:
				return 'Small'
			case 2:
				return 'Medium'
			case 3:
				return 'Large'
			default:
				return 'Small'
		}
	}
	function validateAddress(value) {
		let error
		const length = value.length
		const selected = addresses?.features
			?.map(place => place.place_name)
			.filter(x => x === value)
			.map(x => x === value)[0]
		if (length >= 3 && !selected) {
			error = `select a valid address`
		}
		return error
	}
	const onSubmit = async (values, actions) => {
		actions.setSubmitting(true)
		const requestBody = (address, dealer) => ({
			oid: '00D58000000JkgU',
			retURL: 'http://',
			first_name: values.firstName,
			last_name: values.lastName,
			email: values.email,
			mobile: values.phone,
			'00N4H00000EOB0l': `${values.date} ${values.time}`,
			'00N4H00000EOB0H': dealer, //dealer ID
			street: address?.line1,
			city: address?.city,
			state: address?.state,
			zip: address?.postalCode,
			country: 'Australia',
			'00N4H00000EOB0Z': values.product, // model
			'00N4H00000EOB0i': values.material, // surface
			'00N4H00000EOB0h': poolSize(values.size), // size
			'00N4H00000EOB0g': values.isClean ? 'Clean' : 'Dirty', // condition
			company: `${values.firstName} ${values.lastName}`,
			recordType: '0124H000000QkWL',
			lead_source: 'Website',
			'00N4H00000EOB0W': 'Book a Demo',
			'00N4H00000EBmQp': 'MTAU',
			'00N4H00000Dvvkp': values.updates ? 1 : 0,
			'00N4H00000Dvvku': values.updates ? 1 : 0,
		})
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Access-Control-Allow-Origin': '*',
			},
		}
		const url =
			'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
		const address = await getAddress({ address: values.fullAddress })
		const variables = {
			fullAddress: address?.fullAddress,
			coordinates: address?.coordinates,
			flag: 'leads',
			type: 'elite',
		}
		const {
			data: { data: result, errors },
		} = await axios.post(
			'/.netlify/functions/graphql',
			{
				query: print(CLOSEST_DEALER),
				variables,
			},
			{
				headers: { 'Content-Type': 'application/json' },
			}
		)
		const body = requestBody(address, result.dealerByClosest.salesForceId)
		// console.log('salesforce: ', JSON.stringify(body, undefined, 2))
		axios.post(url, qs.stringify(body))
		actions.resetForm(initialValues)
		actions.setSubmitting(false)
		setSent(true)
		return open()
		// return navigate(`?leadId=${lead.leadId}`)
		// return alert(`TEST: Sent lead to SF`)
	}
	const [searchTerm, setSearchTerm] = useState('')
	const addresses = useAddressSearch(searchTerm)
	const handleSearchTermChange = event => {
		setSearchTerm(event.target.value)
	}
	const CustomDatePicker = styled(DatePicker)`
		${tw`flex appearance-none w-full py-1 px-3 mb-3`}
		${tw`text-gray-700 leading-tight`}
		${tw`bg-white`}
		${tw`focus:shadow-outline focus:outline-none`}
		${tw`border-2 border-black rounded`}
		${({ valid }) => (valid ? tw`border-green-500` : ``)}
		${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
		&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
			box-shadow: ${({ invalid }) =>
				invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
			box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		}
		.react-date-picker__wrapper {
		${tw`border-0`}
		}
	`
	const CustomTimePicker = styled(TimePicker)`
		${tw`appearance-none w-full py-1 px-3 mb-3`}
		${tw`text-gray-700 leading-tight`}
		${tw`bg-white`}
		${tw`focus:shadow-outline focus:outline-none`}
		${tw`border-2 border-black rounded`}
		${({ valid }) => (valid ? tw`border-green-500` : ``)}
		${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
		&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
			box-shadow: ${({ invalid }) =>
				invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
			box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		}
		.react-time-picker__wrapper {
		${tw`border-0`}
		}
	`
	const [updateStatus, setUpdateStatus] = useState(false)

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<ListParagraph>{children}</ListParagraph>
			),
		},
	}
	const options2 = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<MobileListParagraph>{children}</MobileListParagraph>
			),
		},
	}

	const banners = [
		'https://images.ctfassets.net/ftcnv5up9dxv/3CcI0HZhW2vS5ZXiEDe6p4/623b07df4e0166285c635230ad5dc79d/new-book-a-demo-bg.jpg?fit=crop&f=bottom_left&w=2400&h=1100&fm=jpg&fl=progressive&q=85',
		'https://images.ctfassets.net/ftcnv5up9dxv/3CcI0HZhW2vS5ZXiEDe6p4/623b07df4e0166285c635230ad5dc79d/new-book-a-demo-bg.jpg?fit=crop&f=bottom_left&w=1400&h=750&fit=fill&fl=progressive&q=85',
		'https://images.ctfassets.net/ftcnv5up9dxv/3CcI0HZhW2vS5ZXiEDe6p4/623b07df4e0166285c635230ad5dc79d/new-book-a-demo-bg.jpg?fit=crop&f=bottom_left&w=1000&h=500&fit=fill&fl=progressive&q=90',
	]

	let starsArr = []
	for (let index = 0; index < 5; index++) {
		starsArr.push(
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="#fdb515"
				stroke="#fdb515"
				strokeWidth="1"
				stroke-linecap="butt"
				stroke-linejoin="bevel"
				className="feather feather-star"
			>
				<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
			</svg>
		)
	}

	const [showTermsDialog, setShowTermsDialog] = useState(false)
	console.log(showTermsDialog)
	return (
		<Layout>
			<Meta
				title="Book a Demo"
				keywords={['book', 'demo', 'book a demo', 'dolphin demo']}
				description="Discover the Dolphin difference, book a FREE demo with your local Maytronics dealer today!"
			>
				<script>{`!function(f,b,e,v,n,t,s)\n{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};\nif(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\nn.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];\ns.parentNode.insertBefore(t,s)}(window, document,'script',\n'https://connect.facebook.net/en_US/fbevents.js');\nfbq('init', '246870742502371');\nfbq('track', 'PageView');`}</script>
			</Meta>
			<noscript>{`<img alt="facebook pixel" height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=246870742502371&ev=PageView&noscript=1" />`}</noscript>
			{/* <div
				tw="relative bg-dolphin-gray"
				css={css([updateStatus && tw`hidden`])}
			>
				<div tw="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
					<div tw="pr-16 sm:text-center sm:px-16">
						<p tw="font-medium text-white">
							<span tw="md:hidden">
								COVID-19 update, some States are under lockdown
							</span>
							<span tw="hidden md:inline">
								COVID-19 update, some States are under lockdown, please leave
								your details and your local Elite dealer will make contact.
							</span>
						</p>
					</div>
					<div tw="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
						<button
							type="button"
							onClick={() => setUpdateStatus(true)}
							tw="flex p-1 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-white cursor-pointer"
						>
							<span tw="sr-only">Dismiss</span>
							<svg
								tw="h-6 w-6 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div> */}
			<div css={tw`w-full flex flex-col items-center`}>
				<Banner
					bg={banners}
					role="img"
					aria-label="Maytronics Dolphin M 600 robotic pool cleaner sitting on the pool's edge"
				>
					<BannerContents>
						<BannerLeft>
							<h1 tw="font-display text-white text-5xl font-bold">
								Nothing cleans like a Dolphin!
							</h1>

							<ReasonsContainer>
								<ReasonsTitle>{title}</ReasonsTitle>
								<ListContainer>
									{items.map((itm, i) => {
										return (
											<ListDiv>
												<h3 tw="text-yellow-500">{itm.name}</h3>
												{documentToReactComponents(itm.body.json, options)}
											</ListDiv>
										)
									})}
								</ListContainer>
							</ReasonsContainer>

							<Testimonial>
								<h3 tw="text-blue-500 text-base xxl:text-xl font-bold">
									"Awesome Cleaner! Totally Amazing Performance"
								</h3>
								<StarsContainer>
									{starsArr.map(x => (
										<>{x}</>
									))}
								</StarsContainer>
								<div>
									<p tw="xl:text-sm xxl:text-base font-display text-white">
										"We've recently built a new pool and wanted to buy an
										automatic pool cleaner. Our pool shop gave us a demo of the
										M600 robotic pool robot cleaner and it was awesome. The pool
										water is crystal clear - we have a few big trees nearby and
										no other automatic pool leaf cleaner has done quite the same
										job. The swimming pool cleaner is easy to use and has great
										functionality. I even managed to get an extended warranty
										included for extra peace of mind. We had a demo of another
										automatic electric pool cleaner and weren't impressed at
										all. So pleased we have gone for a Dolphin.
									</p>

									<p tw="xl:text-sm xxl:text-base text-blue-500 font-display font-medium mt-3">
										Michelle
									</p>
									<p tw="xl:text-sm xxl:text-base text-yellow-500 font-display text-sm font-medium">
										Queensland
									</p>
								</div>
							</Testimonial>
						</BannerLeft>

						<BannerRight>
							<BookNow href="#book-now">Book Now</BookNow>
							<Phone href="tel:1300693657">Call us on 1300 693 657</Phone>
							<div
								className="container"
								css={tw`px-1 md:px-2 lg:px-8 bg-yellow-600 hidden xl:block h-full`}
							>
								<Heading>BOOK YOUR FREE DEMO</Heading>
								<h2
									css={tw`font-sans text-gray-700 font-medium text-xl mb-10 text-center`}
								>
									{sent === true
										? 'We have recieved you booking details and your local Maytronics dealer will be in contact with you soon.'
										: 'and discover the Dolphin difference!'}
								</h2>
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={onSubmit}
								>
									{({
										isSubmitting,
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
									}) => {
										return (
											<StyledForm css={tw`mx-auto`}>
												<FieldRow>
													<FieldWrap width="1/2">
														<Label htmlFor="firstName">
															First Name
															<Required>*</Required>
														</Label>
														<Input
															type="text"
															name="firstName"
															placeholder="Given names"
															id="firstName"
															valid={
																!errors['firstName'] && touched['firstName']
																	? 1
																	: 0
															}
															invalid={
																errors['firstName'] && touched['firstName']
																	? 1
																	: 0
															}
														/>
														{touched['firstName'] && errors['firstName']
															? ''
															: ''}
													</FieldWrap>
													<FieldWrap width="1/2">
														<Label htmlFor="lastName">
															Last Name
															<Required>*</Required>
														</Label>
														<Input
															type="text"
															name="lastName"
															placeholder="Family names"
															id="lastName"
															valid={
																!errors['lastName'] && touched['lastName']
																	? 1
																	: 0
															}
															invalid={
																errors['lastName'] && touched['lastName']
																	? 1
																	: 0
															}
														/>
														{touched['lastName'] && errors['lastName']
															? ''
															: ''}
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap width="1/2">
														<Label htmlFor="email">
															Email
															<Required>*</Required>
														</Label>
														<Input
															type="email"
															name="email"
															placeholder="Email address"
															id="email"
															valid={
																!errors['email'] && touched['email'] ? 1 : 0
															}
															invalid={
																errors['email'] && touched['email'] ? 1 : 0
															}
														/>
														{touched['email'] && errors['email'] ? '' : ''}
													</FieldWrap>
													<FieldWrap width="1/2">
														<Label htmlFor="phone">
															Phone
															<Required>*</Required>
														</Label>
														<Input
															type="tel"
															name="phone"
															placeholder="Contact number"
															id="phone"
															valid={
																!errors['phone'] && touched['phone'] ? 1 : 0
															}
															invalid={
																errors['phone'] && touched['phone'] ? 1 : 0
															}
														/>
														{touched['phone'] && errors['phone'] ? '' : ''}
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap>
														<Label htmlFor="fullAddress">
															Address
															<Required>*</Required>
														</Label>
														<Input
															name="fullAddress"
															validate={validateAddress}
														>
															{({
																field,
																form: { handleChange, setFieldValue },
																meta,
															}) => (
																<Combobox
																	onSelect={item =>
																		setFieldValue('fullAddress', item)
																	}
																>
																	<StyledComboboxInput
																		className={
																			!meta.error && meta.touched
																				? 'valid'
																				: meta.error && meta.touched
																				? 'invalid'
																				: ''
																		}
																		type="text"
																		{...field}
																		placeholder="Type to search an address"
																		onChange={event => {
																			handleSearchTermChange(event)
																			return handleChange(event)
																		}}
																	/>
																	{addresses && (
																		<ComboboxPopover className="shadow-popup">
																			{addresses &&
																			addresses.features &&
																			addresses.features.length > 0 ? (
																				<ComboboxList
																					className={
																						!meta.error && meta.touched
																							? 'valid contrast-dropdown'
																							: meta.error && meta.touched
																							? 'invalid contrast-dropdown'
																							: 'contrast-dropdown'
																					}
																				>
																					{addresses &&
																						addresses.features.map(place => {
																							return (
																								<ComboboxOption
																									key={place.id}
																									value={place.place_name}
																								/>
																							)
																						})}
																				</ComboboxList>
																			) : (
																				<ComboboxList
																					className={
																						!meta.error && meta.touched
																							? 'valid mapbox'
																							: meta.error && meta.touched
																							? 'invalid mapbox'
																							: 'mapbox'
																					}
																				>
																					<ComboboxOption
																						value={'No results found'}
																					/>
																				</ComboboxList>
																			)}
																		</ComboboxPopover>
																	)}
																</Combobox>
															)}
														</Input>
														{touched['fullAddress'] &&
															errors['fullAddress'] &&
															''}
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap width="1/2">
														<Label htmlFor="date">
															Preferred Date
															<Required>*</Required>
														</Label>
														<Input name="date">
															{({
																field,
																form: { setFieldValue, setFieldTouched },
															}) => {
																const value = moment(field.value).toDate()
																return (
																	<CustomDatePicker
																		value={value}
																		onChange={value => {
																			if (!value) {
																				setFieldTouched('date', true)
																				return setFieldValue(
																					'date',
																					moment()
																						.add(1, 'days')
																						.format('YYYY-MM-DD')
																				)
																			} else {
																				setFieldTouched('date', true)
																				return setFieldValue(
																					'date',
																					moment(value).format('YYYY-MM-DD')
																				)
																			}
																		}}
																		id="date"
																		name={field.name}
																		locale="en-AU"
																		showLeadingZeros
																		// disableCalendar
																		valid={
																			!errors['date'] && touched['date'] ? 1 : 0
																		}
																		invalid={
																			errors['date'] && touched['date'] ? 1 : 0
																		}
																		calendarIcon={<Calendar />}
																		clearIcon={<X />}
																	/>
																)
															}}
														</Input>
														{touched['date'] && errors['date'] ? '' : ''}
													</FieldWrap>
													<FieldWrap width="1/2">
														<Label htmlFor="time">
															Preferred Time(24hr)
															<Required>*</Required>
														</Label>
														<Input name="time">
															{({
																field,
																form: {
																	setFieldValue,
																	setFieldTouched,
																	values,
																},
															}) => {
																return (
																	<CustomTimePicker
																		value={field.value}
																		onChange={value => {
																			if (!value) {
																				setFieldTouched('time', true)
																				return setFieldValue('time', '08:00:00')
																			} else {
																				setFieldTouched('time', true)
																				return setFieldValue(
																					'time',
																					field.value
																				)
																			}
																		}}
																		id="time"
																		name={field.name}
																		locale="en-AU"
																		format="HH:mm:ss"
																		valid={
																			!errors['time'] && touched['time'] ? 1 : 0
																		}
																		invalid={
																			errors['time'] && touched['time'] ? 1 : 0
																		}
																		disableClock
																		clearIcon={<X />}
																		clockIcon={<Clock />}
																	/>
																)
															}}
														</Input>
														{touched['time'] && errors['time'] ? '' : ''}
													</FieldWrap>
												</FieldRow>

												<FieldRow>
													<FieldWrap>
														<p tw="mb-3">
															<strong>Please note</strong>: this is only
															preferred date and time. Your local dealer will
															confirm your demo.
														</p>
													</FieldWrap>
												</FieldRow>

												<FieldRow>
													<FieldWrap width="1/2">
														<Label htmlFor="material">Pool Material</Label>
														<SelectWrap css={tw`mb-3`}>
															<Select
																component={'select'}
																multiple={false}
																name="material"
																id="material"
															>
																<option
																	className="placeholder"
																	css={tw`text-gray-400`}
																	value=""
																>
																	Select
																</option>
																<option>Tile</option>
																<option>Pebble</option>
																<option>Concrete</option>
																<option>Fibreglass</option>
																<option>Vinyl</option>
															</Select>
															<SelectIcon aria-hidden>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 20 20"
																>
																	<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
																</svg>
															</SelectIcon>
														</SelectWrap>
													</FieldWrap>
													<FieldWrap width="1/2">
														<Label htmlFor="isClean">Pool State</Label>
														<ToggleBox css={tw`mb-3`}>
															<Field name="isClean" type="checkbox">
																{({ field, form: { setFieldValue } }) => (
																	<>
																		<span
																			role="button"
																			tabIndex="-1"
																			className={
																				!field.checked
																					? 'active state'
																					: 'state'
																			}
																			css={tw`cursor-pointer`}
																			onClick={() =>
																				setFieldValue('isClean', false)
																			}
																			onKeyDown={() =>
																				setFieldValue('isClean', false)
																			}
																		>
																			Dirty
																		</span>
																		<CheckboxContainer>
																			<CustomCheckboxInput
																				{...field}
																				type="checkbox"
																			/>
																			<div
																				role="button"
																				tabIndex="-1"
																				css={tw`w-6 h-6 cursor-pointer mx-2`}
																				onClick={() =>
																					setFieldValue(
																						'isClean',
																						field.checked ? false : true
																					)
																				}
																				onKeyDown={() =>
																					setFieldValue(
																						'isClean',
																						field.checked ? false : true
																					)
																				}
																				aria-label="Toggle"
																			>
																				<Lottie
																					playingState={'playing'}
																					speed={1}
																					direction={field.checked ? 1 : -1}
																					width={24}
																					height={24}
																					className="lottie-container checkbox"
																					config={{
																						animationData:
																							animationToggle.default,
																						loop: false,
																						autoplay: true,
																						renderer: 'svg',
																					}}
																					aria-hidden
																				/>
																			</div>
																		</CheckboxContainer>
																		<span
																			role="button"
																			tabIndex="-1"
																			className={
																				field.checked ? 'active state' : 'state'
																			}
																			css={tw`cursor-pointer`}
																			onClick={() =>
																				setFieldValue('isClean', true)
																			}
																			onKeyDown={() =>
																				setFieldValue('isClean', true)
																			}
																		>
																			Clean
																		</span>
																	</>
																)}
															</Field>
														</ToggleBox>
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap width="1/2">
														<Label htmlFor="product">Demo Model</Label>
														<SelectWrap css={tw`mb-3`}>
															<Select
																component={'select'}
																multiple={false}
																name="product"
																id="product"
															>
																<option
																	className="placeholder"
																	css={tw`text-gray-400`}
																	value=""
																>
																	Select
																</option>
																<option value="M700">M 700</option>
																<option value="M600">M 600</option>
																<option value="M400">M 400</option>
																<option value="S200">S 200</option>
															</Select>
															<SelectIcon aria-hidden>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 20 20"
																>
																	<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
																</svg>
															</SelectIcon>
														</SelectWrap>
													</FieldWrap>
													<FieldWrap width="1/2">
														<Label htmlFor="size">Pool Size</Label>
														<Field name="size">
															{({ field, form: { setFieldValue } }) => (
																<>
																	<SliderInput
																		min={1}
																		max={3}
																		step={1}
																		name={field.name}
																		onChange={value =>
																			setFieldValue('size', value)
																		}
																		css={tw`h-2 -mt-2 w-full`}
																	>
																		<SliderTrack css={tw`bg-gray-400`}>
																			<SliderTrackHighlight
																				css={tw`bg-gray-700`}
																			/>
																			<StyledSliderMarker value={1}>
																				<span>Small</span>
																			</StyledSliderMarker>
																			<StyledSliderMarker value={2}>
																				<span>Medium</span>
																			</StyledSliderMarker>
																			<StyledSliderMarker value={3}>
																				<span>Large</span>
																			</StyledSliderMarker>
																			<SliderHandle
																				css={tw`h-3 w-3 border-2 border-gray-700`}
																			/>
																		</SliderTrack>
																	</SliderInput>
																</>
															)}
														</Field>
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap>
														<Label htmlFor="terms">
															Terms & Conditions
															<Required>*</Required>
														</Label>
														<Field name="terms" type="checkbox">
															{({
																field,
																form: { setFieldValue, setTouched },
															}) => (
																<div tw="flex flex-wrap w-full items-center">
																	<p
																		tw="w-full md:w-1/2 md:my-4 text-center cursor-pointer bg-gray-700 hover:bg-gray-800 text-white rounded py-1"
																		onClick={() => {
																			setTouched({ terms: true })
																			setShowTermsDialog(true)
																		}}
																		onKeyDown={() =>
																			setTouched({ terms: true })
																		}
																	>
																		Read Terms & Conditions
																	</p>
																	<DialogOverlay
																		onDismiss={() => setShowTermsDialog(false)}
																		isOpen={showTermsDialog}
																		tw="w-full -mb-2"
																	>
																		<DialogPanel>
																			<CloseButton
																				onClick={() =>
																					setShowTermsDialog(false)
																				}
																			/>
																			<h3>Free Demo Terms</h3>
																			<ul>
																				<li>
																					<b>Under 30 minutes</b> – you can ask
																					us as many questions as you want about
																					the Dolphin
																				</li>
																				<li>
																					<b>No Trespassing</b> – with your
																					permission we’ll go directly to your
																					pool and show you the benefits of
																					owning a Dolphin robot
																				</li>
																				<li>
																					<b>Availability</b> – our dealers come
																					to you 6-days a week (Monday to
																					Saturday*) 8am to 5pm. *Demos are
																					subject to dealer availability
																				</li>
																				<li>
																					<b>Independent and local</b> – we’re
																					proud to support independent and local
																					businesses who will provide you with
																					exceptional service, support and
																					product information
																				</li>
																				<li>
																					<b>Clear Pool</b> – demos can’t be
																					performed in a green/dirty pool
																				</li>
																			</ul>
																			<h3>Fair Demo Policy</h3>
																			<p>
																				Maytronics endeavours to demo our
																				products to all genuine customers on the
																				understanding there is an intent to
																				purchase following a successful
																				demonstration that meets the needs and
																				expectations of the customer.
																			</p>
																			<p>
																				PLEASE NOTE: Free Demos are conducted at
																				the discretion of individual Dealers who
																				reserve the right to determine what is
																				‘within a reasonable distance’ for their
																				business.
																			</p>
																		</DialogPanel>
																	</DialogOverlay>
																	<AcceptTerms>
																		<CheckboxContainer
																			tw="w-6 h-6 cursor-pointer rounded-sm ml-3 mr-2"
																			onClick={() => {
																				setFieldValue(
																					'terms',
																					field.checked ? false : true
																				)
																			}}
																			onKeyDown={() =>
																				setFieldValue(
																					'terms',
																					field.checked ? false : true
																				)
																			}
																		>
																			<BookADemoCheckbox
																				{...field}
																				checked={field.checked}
																				name="terms"
																				type="checkbox"
																				invalid={
																					errors['terms'] && touched['terms']
																						? 1
																						: 0
																				}
																			/>
																			<Lottie
																				playingState={'playing'}
																				speed={1}
																				direction={field.checked ? 1 : -1}
																				width={24}
																				height={24}
																				tw="bg-white rounded"
																				className="lottie-container checkbox"
																				config={{
																					animationData:
																						animationCheckbox.default,
																					loop: false,
																					autoplay: true,
																					renderer: 'svg',
																				}}
																				aria-hidden
																			/>
																		</CheckboxContainer>
																		Accept Terms & Conditions
																	</AcceptTerms>
																</div>
															)}
														</Field>
														{touched['terms'] && errors['terms'] ? '' : <></>}
													</FieldWrap>
												</FieldRow>
												{/* <FieldRow>
										<FieldWrap>
											<pre>{JSON.stringify(consumerCache, null, 2)}</pre>
										</FieldWrap>
									</FieldRow> */}
												<FieldRow>
													<FieldWrap>
														<Field name="readandagree" type="checkbox">
															{({
																field,
																form: { setFieldValue, setTouched },
															}) => (
																<div tw="flex w-full mb-4 items-center">
																	<CheckboxContainer
																		tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
																		onClick={() =>
																			setFieldValue(
																				'readandagree',
																				field.checked ? false : true
																			)
																		}
																		onKeyDown={() =>
																			setFieldValue(
																				'readandagree',
																				field.checked ? false : true
																			)
																		}
																	>
																		<BookADemoCheckbox
																			{...field}
																			checked={field.checked}
																			name="readandagree"
																			type="checkbox"
																			invalid={
																				errors['readandagree'] &&
																				touched['readandagree']
																					? 1
																					: 0
																			}
																		/>
																		<Lottie
																			playingState={'playing'}
																			speed={1}
																			direction={field.checked ? 1 : -1}
																			width={24}
																			height={24}
																			tw="bg-white rounded"
																			className="lottie-container checkbox"
																			config={{
																				animationData:
																					animationCheckbox.default,
																				loop: false,
																				autoplay: true,
																				renderer: 'svg',
																			}}
																			aria-hidden
																		/>
																	</CheckboxContainer>
																	<p tw="text-sm font-display">
																		I have read and I agree to{' '}
																		<a
																			tw="font-bold underline hover:no-underline"
																			href="/terms/"
																			target="_blank"
																		>
																			Maytronics Terms &#38; Conditions
																		</a>{' '}
																		and{' '}
																		<a
																			tw="font-bold underline hover:no-underline"
																			href="/privacy/"
																			target="_blank"
																		>
																			Privacy Policy
																		</a>{' '}
																		<Required>*</Required>
																	</p>
																</div>
															)}
														</Field>
														{touched['readandagree'] &&
														errors['readandagree'] ? (
															''
														) : (
															<></>
														)}
													</FieldWrap>
												</FieldRow>
												<FieldRow>
													<FieldWrap>
														<Field name="updates" type="checkbox">
															{({
																field,
																form: { setFieldValue, setTouched },
															}) => (
																<div tw="flex w-full mb-4 items-center">
																	<CheckboxContainer
																		tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
																		onClick={() =>
																			setFieldValue(
																				'updates',
																				field.checked ? false : true
																			)
																		}
																		onKeyDown={() =>
																			setFieldValue(
																				'updates',
																				field.checked ? false : true
																			)
																		}
																	>
																		<CustomCheckboxInput
																			{...field}
																			checked={field.checked}
																			name="updates"
																			type="checkbox"
																		/>
																		<Lottie
																			playingState={'playing'}
																			speed={1}
																			direction={field.checked ? 1 : -1}
																			width={24}
																			height={24}
																			tw="bg-white rounded"
																			className="lottie-container checkbox"
																			config={{
																				animationData:
																					animationCheckbox.default,
																				loop: false,
																				autoplay: true,
																				renderer: 'svg',
																			}}
																			aria-hidden
																		/>
																	</CheckboxContainer>
																	<p tw="text-sm font-display">
																		I wish to receive updates and promotions
																		from Maytronics.
																	</p>
																</div>
															)}
														</Field>
													</FieldWrap>
												</FieldRow>
												<ButtonRow>
													<BookADemoBtn type="submit" disabled={isSubmitting}>
														{isSubmitting ? 'Booking' : 'Book'}
														{isSubmitting && (
															<Lottie
																width="18px"
																height="18px"
																className="lottie-container loading"
																config={{
																	animationData: animationData.default,
																	loop: true,
																	autoplay: true,
																	renderer: 'svg',
																}}
																aria-hidden
															/>
														)}
													</BookADemoBtn>
												</ButtonRow>
											</StyledForm>
										)
									}}
								</Formik>
								<PopUp showDialog={showDialog} close={close} />
							</div>
						</BannerRight>
					</BannerContents>
				</Banner>

				<MobileContainer>
					<MobileReasonsTitle>{title}</MobileReasonsTitle>

					<MobileReasonsContainer>
						{itemsMobile.map((itm, i) => {
							return (
								<MobileListDiv>
									<h3 tw="text-yellow-500">{itm.name}</h3>
									{documentToReactComponents(itm.body.json, options2)}
								</MobileListDiv>
							)
						})}
					</MobileReasonsContainer>
				</MobileContainer>

				<div
					id="book-now"
					className="container"
					css={tw`px-4 md:px-2 lg:px-4 block xl:hidden`}
				>
					<Heading>BOOK YOUR FREE DEMO</Heading>
					<h2
						css={tw`font-sans text-gray-700 font-medium text-xl mb-10 text-center`}
					>
						{sent === true
							? 'We have recieved you booking details and your local Maytronics dealer will be in contact with you soon.'
							: 'and discover the Dolphin difference!'}
					</h2>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({
							isSubmitting,
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
						}) => {
							return (
								<StyledForm css={tw`mx-auto`}>
									<FieldRow>
										<FieldWrap width="1/2">
											<Label htmlFor="firstName">
												First Name
												<Required>*</Required>
											</Label>
											<Input
												type="text"
												name="firstName"
												placeholder="Given names"
												id="firstName"
												valid={
													!errors['firstName'] && touched['firstName'] ? 1 : 0
												}
												invalid={
													errors['firstName'] && touched['firstName'] ? 1 : 0
												}
											/>
											{touched['firstName'] && errors['firstName'] ? '' : ''}
										</FieldWrap>
										<FieldWrap width="1/2">
											<Label htmlFor="lastName">
												Last Name
												<Required>*</Required>
											</Label>
											<Input
												type="text"
												name="lastName"
												placeholder="Family names"
												id="lastName"
												valid={
													!errors['lastName'] && touched['lastName'] ? 1 : 0
												}
												invalid={
													errors['lastName'] && touched['lastName'] ? 1 : 0
												}
											/>
											{touched['lastName'] && errors['lastName'] ? '' : ''}
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap width="1/2">
											<Label htmlFor="email">
												Email
												<Required>*</Required>
											</Label>
											<Input
												type="email"
												name="email"
												placeholder="Email address"
												id="email"
												valid={!errors['email'] && touched['email'] ? 1 : 0}
												invalid={errors['email'] && touched['email'] ? 1 : 0}
											/>
											{touched['email'] && errors['email'] ? '' : ''}
										</FieldWrap>
										<FieldWrap width="1/2">
											<Label htmlFor="phone">
												Phone
												<Required>*</Required>
											</Label>
											<Input
												type="tel"
												name="phone"
												placeholder="Contact number"
												id="phone"
												valid={!errors['phone'] && touched['phone'] ? 1 : 0}
												invalid={errors['phone'] && touched['phone'] ? 1 : 0}
											/>
											{touched['phone'] && errors['phone'] ? '' : ''}
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap>
											<Label htmlFor="fullAddress">
												Address
												<Required>*</Required>
											</Label>
											<Input name="fullAddress" validate={validateAddress}>
												{({
													field,
													form: { handleChange, setFieldValue },
													meta,
												}) => (
													<Combobox
														onSelect={item =>
															setFieldValue('fullAddress', item)
														}
													>
														<StyledComboboxInput
															className={
																!meta.error && meta.touched
																	? 'valid'
																	: meta.error && meta.touched
																	? 'invalid'
																	: ''
															}
															type="text"
															{...field}
															placeholder="Type to search an address"
															onChange={event => {
																handleSearchTermChange(event)
																return handleChange(event)
															}}
														/>
														{addresses && (
															<ComboboxPopover className="shadow-popup">
																{addresses &&
																addresses.features &&
																addresses.features.length > 0 ? (
																	<ComboboxList
																		className={
																			!meta.error && meta.touched
																				? 'valid contrast-dropdown'
																				: meta.error && meta.touched
																				? 'invalid contrast-dropdown'
																				: 'contrast-dropdown'
																		}
																	>
																		{addresses &&
																			addresses.features.map(place => {
																				return (
																					<ComboboxOption
																						key={place.id}
																						value={place.place_name}
																					/>
																				)
																			})}
																	</ComboboxList>
																) : (
																	<ComboboxList
																		className={
																			!meta.error && meta.touched
																				? 'valid mapbox'
																				: meta.error && meta.touched
																				? 'invalid mapbox'
																				: 'mapbox'
																		}
																	>
																		<ComboboxOption
																			value={'No results found'}
																		/>
																	</ComboboxList>
																)}
															</ComboboxPopover>
														)}
													</Combobox>
												)}
											</Input>
											{touched['fullAddress'] && errors['fullAddress'] && ''}
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap width="1/2">
											<Label htmlFor="date">
												Preferred Date
												<Required>*</Required>
											</Label>
											<Input name="date">
												{({
													field,
													form: { setFieldValue, setFieldTouched },
												}) => {
													const value = moment(field.value).toDate()
													return (
														<CustomDatePicker
															value={value}
															onChange={value => {
																if (!value) {
																	setFieldTouched('date', true)
																	return setFieldValue(
																		'date',
																		moment()
																			.add(1, 'days')
																			.format('YYYY-MM-DD')
																	)
																} else {
																	setFieldTouched('date', true)
																	return setFieldValue(
																		'date',
																		moment(value).format('YYYY-MM-DD')
																	)
																}
															}}
															id="date"
															name={field.name}
															locale="en-AU"
															showLeadingZeros
															// disableCalendar
															valid={!errors['date'] && touched['date'] ? 1 : 0}
															invalid={
																errors['date'] && touched['date'] ? 1 : 0
															}
															calendarIcon={<Calendar />}
															clearIcon={<X />}
														/>
													)
												}}
											</Input>
											{touched['date'] && errors['date'] ? '' : ''}
										</FieldWrap>
										<FieldWrap width="1/2">
											<Label htmlFor="time">
												Preferred Time(24hr)
												<Required>*</Required>
											</Label>
											<Input name="time">
												{({
													field,
													form: { setFieldValue, setFieldTouched, values },
												}) => {
													return (
														<CustomTimePicker
															value={field.value}
															onChange={value => {
																if (!value) {
																	setFieldTouched('time', true)
																	return setFieldValue('time', '08:00:00')
																} else {
																	setFieldTouched('time', true)
																	return setFieldValue('time', field.value)
																}
															}}
															id="time"
															name={field.name}
															locale="en-AU"
															format="HH:mm:ss"
															valid={!errors['time'] && touched['time'] ? 1 : 0}
															invalid={
																errors['time'] && touched['time'] ? 1 : 0
															}
															disableClock
															clearIcon={<X />}
															clockIcon={<Clock />}
														/>
													)
												}}
											</Input>
											{touched['time'] && errors['time'] ? '' : ''}
										</FieldWrap>
									</FieldRow>

									<FieldRow>
										<FieldWrap>
											<p tw="mb-3">
												<strong>Please note</strong>: this is only preferred
												date and time. Your local dealer will confirm your demo.
											</p>
										</FieldWrap>
									</FieldRow>

									<FieldRow>
										<FieldWrap width="1/2">
											<Label htmlFor="material">Pool Material</Label>
											<SelectWrap css={tw`mb-3`}>
												<Select
													component={'select'}
													multiple={false}
													name="material"
													id="material"
												>
													<option
														className="placeholder"
														css={tw`text-gray-400`}
														value=""
													>
														Select
													</option>
													<option>Tile</option>
													<option>Pebble</option>
													<option>Concrete</option>
													<option>Fibreglass</option>
													<option>Vinyl</option>
												</Select>
												<SelectIcon aria-hidden>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</SelectIcon>
											</SelectWrap>
										</FieldWrap>
										<FieldWrap width="1/2">
											<Label htmlFor="isClean">Pool State</Label>
											<ToggleBox css={tw`mb-3`}>
												<Field name="isClean" type="checkbox">
													{({ field, form: { setFieldValue } }) => (
														<>
															<span
																role="button"
																tabIndex="-1"
																className={
																	!field.checked ? 'active state' : 'state'
																}
																css={tw`cursor-pointer`}
																onClick={() => setFieldValue('isClean', false)}
																onKeyDown={() =>
																	setFieldValue('isClean', false)
																}
															>
																Dirty
															</span>
															<CheckboxContainer>
																<CustomCheckboxInput
																	{...field}
																	type="checkbox"
																/>
																<div
																	role="button"
																	tabIndex="-1"
																	css={tw`w-6 h-6 cursor-pointer mx-2`}
																	onClick={() =>
																		setFieldValue(
																			'isClean',
																			field.checked ? false : true
																		)
																	}
																	onKeyDown={() =>
																		setFieldValue(
																			'isClean',
																			field.checked ? false : true
																		)
																	}
																	aria-label="Toggle"
																>
																	<Lottie
																		playingState={'playing'}
																		speed={1}
																		direction={field.checked ? 1 : -1}
																		width={24}
																		height={24}
																		className="lottie-container checkbox"
																		config={{
																			animationData: animationToggle.default,
																			loop: false,
																			autoplay: true,
																			renderer: 'svg',
																		}}
																		aria-hidden
																	/>
																</div>
															</CheckboxContainer>
															<span
																role="button"
																tabIndex="-1"
																className={
																	field.checked ? 'active state' : 'state'
																}
																css={tw`cursor-pointer`}
																onClick={() => setFieldValue('isClean', true)}
																onKeyDown={() => setFieldValue('isClean', true)}
															>
																Clean
															</span>
														</>
													)}
												</Field>
											</ToggleBox>
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap width="1/2">
											<Label htmlFor="product">Demo Model</Label>
											<SelectWrap css={tw`mb-3`}>
												<Select
													component={'select'}
													multiple={false}
													name="product"
													id="product"
												>
													<option
														className="placeholder"
														css={tw`text-gray-400`}
														value=""
													>
														Select
													</option>
													<option value="M700">M 700</option>
													<option value="M600">M 600</option>
													<option value="M400">M 400</option>
													<option value="S300i">S 300i</option>
													<option value="S200">S 200</option>
												</Select>
												<SelectIcon aria-hidden>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 20 20"
													>
														<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
													</svg>
												</SelectIcon>
											</SelectWrap>
										</FieldWrap>
										<FieldWrap width="1/2">
											<Label htmlFor="size">Pool Size</Label>
											<Field name="size">
												{({ field, form: { setFieldValue } }) => (
													<>
														<SliderInput
															min={1}
															max={3}
															step={1}
															name={field.name}
															onChange={value => setFieldValue('size', value)}
															css={tw`h-2 -mt-2 w-full`}
														>
															<SliderTrack css={tw`bg-gray-400`}>
																<SliderTrackHighlight css={tw`bg-gray-700`} />
																<StyledSliderMarker value={1}>
																	<span>Small</span>
																</StyledSliderMarker>
																<StyledSliderMarker value={2}>
																	<span>Medium</span>
																</StyledSliderMarker>
																<StyledSliderMarker value={3}>
																	<span>Large</span>
																</StyledSliderMarker>
																<SliderHandle
																	css={tw`h-3 w-3 border-2 border-gray-700`}
																/>
															</SliderTrack>
														</SliderInput>
													</>
												)}
											</Field>
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap>
											<Label htmlFor="terms">
												Terms & Conditions
												<Required>*</Required>
											</Label>
											<Field name="terms" type="checkbox">
												{({ field, form: { setFieldValue, setTouched } }) => (
													<div tw="flex flex-wrap w-full items-center">
														<p
															tw="w-full md:w-1/2 md:my-4 text-center cursor-pointer bg-gray-700 hover:bg-gray-800 text-white rounded py-1 mt-2"
															onClick={() => {
																setTouched({ terms: true })
																setShowTermsDialog(true)
															}}
															onKeyDown={() => setTouched({ terms: true })}
														>
															Read Terms & Conditions
														</p>
														<DialogOverlay
															onDismiss={() => setShowTermsDialog(false)}
															isOpen={showTermsDialog}
															tw="w-full -mb-2"
														>
															<DialogPanel>
																<CloseButton
																	onClick={() => setShowTermsDialog(false)}
																/>
																<h3>Free Demo Terms</h3>
																<ul>
																	<li>
																		<b>Under 30 minutes</b> – you can ask us as
																		many questions as you want about the Dolphin
																	</li>
																	<li>
																		<b>No Trespassing</b> – with your permission
																		we’ll go directly to your pool and show you
																		the benefits of owning a Dolphin robot
																	</li>
																	<li>
																		<b>Availability</b> – our dealers come to
																		you 6-days a week (Monday to Saturday*) 8am
																		to 5pm. *Demos are subject to dealer
																		availability
																	</li>
																	<li>
																		<b>Independent and local</b> – we’re proud
																		to support independent and local businesses
																		who will provide you with exceptional
																		service, support and product information
																	</li>
																	<li>
																		<b>Clear Pool</b> – demos can’t be performed
																		in a green/dirty pool
																	</li>
																</ul>
																<h3>Fair Demo Policy</h3>
																<p>
																	Maytronics endeavours to demo our products to
																	all genuine customers on the understanding
																	there is an intent to purchase following a
																	successful demonstration that meets the needs
																	and expectations of the customer.
																</p>
																<p>
																	PLEASE NOTE: Free Demos are conducted at the
																	discretion of individual Dealers who reserve
																	the right to determine what is ‘within a
																	reasonable distance’ for their business.
																</p>
															</DialogPanel>
														</DialogOverlay>
														<AcceptTerms>
															<CheckboxContainer
																tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
																onClick={() => {
																	setFieldValue(
																		'terms',
																		field.checked ? false : true
																	)
																}}
																onKeyDown={() =>
																	setFieldValue(
																		'terms',
																		field.checked ? false : true
																	)
																}
															>
																<BookADemoCheckbox
																	{...field}
																	checked={field.checked}
																	name="terms"
																	type="checkbox"
																	invalid={
																		errors['terms'] && touched['terms'] ? 1 : 0
																	}
																/>
																<Lottie
																	playingState={'playing'}
																	speed={1}
																	direction={field.checked ? 1 : -1}
																	width={24}
																	height={24}
																	tw="bg-white rounded"
																	className="lottie-container checkbox"
																	config={{
																		animationData: animationCheckbox.default,
																		loop: false,
																		autoplay: true,
																		renderer: 'svg',
																	}}
																	aria-hidden
																/>
															</CheckboxContainer>
															Accept Terms & Conditions
														</AcceptTerms>
													</div>
												)}
											</Field>
											{touched['terms'] && errors['terms'] ? '' : <></>}
										</FieldWrap>
									</FieldRow>
									{/* <FieldRow>
										<FieldWrap>
											<pre>{JSON.stringify(consumerCache, null, 2)}</pre>
										</FieldWrap>
									</FieldRow> */}
									<FieldRow>
										<FieldWrap>
											<Field name="readandagree" type="checkbox">
												{({ field, form: { setFieldValue, setTouched } }) => (
													<div tw="flex w-full mb-4 items-center">
														<CheckboxContainer
															tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
															onClick={() =>
																setFieldValue(
																	'readandagree',
																	field.checked ? false : true
																)
															}
															onKeyDown={() =>
																setFieldValue(
																	'readandagree',
																	field.checked ? false : true
																)
															}
														>
															<BookADemoCheckbox
																{...field}
																checked={field.checked}
																name="readandagree"
																type="checkbox"
																invalid={
																	errors['readandagree'] &&
																	touched['readandagree']
																		? 1
																		: 0
																}
															/>
															<Lottie
																playingState={'playing'}
																speed={1}
																direction={field.checked ? 1 : -1}
																width={24}
																height={24}
																tw="bg-white rounded"
																className="lottie-container checkbox"
																config={{
																	animationData: animationCheckbox.default,
																	loop: false,
																	autoplay: true,
																	renderer: 'svg',
																}}
																aria-hidden
															/>
														</CheckboxContainer>
														<p tw="text-sm font-display">
															I have read and I agree to{' '}
															<a
																tw="font-bold underline hover:no-underline"
																href="/terms/"
																target="_blank"
															>
																Maytronics Terms &#38; Conditions
															</a>{' '}
															and{' '}
															<a
																tw="font-bold underline hover:no-underline"
																href="/privacy/"
																target="_blank"
															>
																Privacy Policy
															</a>{' '}
															<Required>*</Required>
														</p>
													</div>
												)}
											</Field>
											{touched['readandagree'] && errors['readandagree'] ? (
												''
											) : (
												<></>
											)}
										</FieldWrap>
									</FieldRow>
									<FieldRow>
										<FieldWrap>
											<Field name="updates" type="checkbox">
												{({ field, form: { setFieldValue, setTouched } }) => (
													<div tw="flex w-full mb-4 items-center">
														<CheckboxContainer
															tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
															onClick={() =>
																setFieldValue(
																	'updates',
																	field.checked ? false : true
																)
															}
															onKeyDown={() =>
																setFieldValue(
																	'updates',
																	field.checked ? false : true
																)
															}
														>
															<CustomCheckboxInput
																{...field}
																checked={field.checked}
																name="updates"
																type="checkbox"
															/>
															<Lottie
																playingState={'playing'}
																speed={1}
																direction={field.checked ? 1 : -1}
																width={24}
																height={24}
																tw="bg-white rounded"
																className="lottie-container checkbox"
																config={{
																	animationData: animationCheckbox.default,
																	loop: false,
																	autoplay: true,
																	renderer: 'svg',
																}}
																aria-hidden
															/>
														</CheckboxContainer>
														<p tw="text-sm font-display">
															I wish to receive updates and promotions from
															Maytronics.
														</p>
													</div>
												)}
											</Field>
										</FieldWrap>
									</FieldRow>
									<ButtonRow>
										<BookADemoBtn type="submit" disabled={isSubmitting}>
											{isSubmitting ? 'Booking' : 'Book'}
											{isSubmitting && (
												<Lottie
													width="18px"
													height="18px"
													className="lottie-container loading"
													config={{
														animationData: animationData.default,
														loop: true,
														autoplay: true,
														renderer: 'svg',
													}}
													aria-hidden
												/>
											)}
										</BookADemoBtn>
									</ButtonRow>
								</StyledForm>
							)
						}}
					</Formik>
					<PopUp showDialog={showDialog} close={close} />
				</div>
			</div>
		</Layout>
	)
}

export const bookADemoQuery = graphql`
	query bookADemoQuery {
		desktop: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "5p6cQpd71K1au1F88DlCsh" } }
		) {
			edges {
				node {
					node_locale
					title
					items {
						name
						body {
							json
						}
					}
				}
			}
		}
		mobile: allContentfulFeaturedItems(
			filter: { contentful_id: { eq: "6EyKwFe7yGiPUfXPDzO773" } }
		) {
			edges {
				node {
					node_locale
					title
					items {
						name
						body {
							json
						}
					}
				}
			}
		}
	}
`

function useAddressSearch(searchTerm) {
	const [addresses, setAddresses] = useState([])
	useEffect(() => {
		if (searchTerm.trim() !== '') {
			let isFresh = true
			fetchAddresses(searchTerm)
				.then(addresses => {
					if (!isFresh) ''
					return setAddresses(addresses)
				})
				.catch(() => '')
			return () => (isFresh = false)
		}
	}, [searchTerm])
	return addresses
}
const cache = {}
export const fetchAddresses = async value => {
	const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN
	const ENDPOINT = 'mapbox.places'
	const SEARCH_TEXT = encodeURI(value)
	const URI = `https://api.mapbox.com/geocoding/v5/${ENDPOINT}/${SEARCH_TEXT}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=au&types=address&limit=5`
	if (cache[value]) {
		return Promise.resolve(cache[value])
	}
	try {
		const response = await axios.get(URI)
		if (response.status === 404)
			throw new Error('MapBox found no matches for that address / postcode.')
		return response.data
	} catch (error) {
		return ''
	}
}
